<template>
  <div
    v-if="showBanner"
    class="absolute left-[50%]"
  >
    <div class="translate-x-[-50%] py-2 px-4 text-white rounded-b-lg text-nowrap" :class="backgroundClass">You are on the <strong>{{ environment }}</strong> environment.</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const environment = computed(() => import.meta.env.VITE_APP_ENVIRONMENT)

const showBanner = computed(() => ['staging', 'development'].includes(environment.value))

const backgroundClass = computed (() => {
  switch (environment.value) {
    case 'staging':
      return 'bg-red-600'
    case 'development':
    default:
      return 'bg-orange-500'
  }
})
</script>