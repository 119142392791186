<template>
  <component :is="icon" :key="name" :class="className" />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'

const props = defineProps({
  /**
   * Required name of the svg file
   */
  name: {
    type: String,
    required: true,
  },
  /**
   * Optional title
   */
  title: {
    type: String,
    default: '',
  },
})

const icon = computed(() =>
  defineAsyncComponent(() => import(`../assets/icons/${props.name}.svg`))
)

const className = computed(() => `svg-icon svg-icon--${props.name}`)
</script>

<style>
.svg-icon {
  @apply stroke-current stroke-[1.2px];

  path {
    @apply fill-none;
  }
}
</style>